<template>
  <div>
    <vs-col vs-type="flex" vs-align="center">
      <div class="cd-button-group">
        <vs-button type="flat" @click="backFunction" icon="reply"></vs-button>
      </div>
      <div class="cd-button-group">
        <h5>{{getTypeName(opinionType)}}</h5>
      </div>
      <vs-chip :color="opinionStatus==1 ? 'success':'danger' ">
        {{opinionStatus_methods(opinionStatus)}}
      </vs-chip>
    </vs-col>
    <!--被邀請會診 selectid=1 start -->
    <vs-col v-if="opinionStatus==0 && selectid==1" class="my-2">
      <el-input
        type="textarea"
        :rows="2"
        :placeholder="$t('EnterSuggestedContent')"
        v-model="opinion">
      </el-input>
      <vs-button class="mt-2" size="small" @click="saveOpinion" :disabled="opinionStatus==1 && selectid==1">{{$t('Save')}}</vs-button>
    </vs-col>
    <vs-col v-if="opinionStatus==1 && selectid==1" class="my-2">
      {{opinionAllData.updated_at}}
      {{myProfile.family_name}}{{myProfile.name}}:{{opinion}}
    </vs-col>
    <vs-col v-if="selectid==1">
      {{opinionAllData.created_at}}
      {{opinionAllData.dname}}:{{opinionAllData.message}}
    </vs-col>
    <!--被邀請會診 selectid=1 end -->
    <!--邀請會診 selectid=2 start -->
    <vs-col v-if="opinionStatus==0 && selectid==2" class="my-2">
        <el-input
          type="textarea"
          :rows="2"
          :placeholder="$t('EnterSuggestedContent')"
          v-model="opinion">
        </el-input>
        <el-button class="mt-2" type="warning" @click="saveOpinion" :disabled="opinionStatus==1 && selectid==2">{{$t('Edit')}}</el-button>
    </vs-col>
    <vs-col v-if="opinionStatus==1 && selectid==2" class="my-2">
      {{opinionAllData.created_at}}
      {{myProfile.family_name}}{{myProfile.name}}:{{opinionAllData.message}}
    </vs-col>
    <vs-col v-if="selectid==2">
      {{opinionAllData.updated_at}}
      {{opinionAllData.dname}}:{{opinionAllData.opinion}}
    </vs-col>
    <!--邀請會診 selectid=2 end -->
    <!-- pdf -->
    <vs-col class="my-2" v-if="opinionType==1 || (opinionType==5 && !check_ecg_chart)">
      <vx-card :title="$t('opinion.'+opinionType==5?'generalPhoto':'ecg')" class="p-2">
        <iframe width="100%"
        height="500vh"
        :src="pdfurl"
        frameborder="0" allowfullscreen></iframe>
      </vx-card>
    </vs-col>
    <!-- h2 plus心電圖 -->
    <vs-col class="my-2" v-if="opinionType==5&& check_ecg_chart">
      <vx-card :title="$t('opinion.'+opinionType==5?'generalPhoto':'ecg')" class="p-2">
        <line-chart :chart-data="ECGData" :time="date" :spid="selectpid"></line-chart>
      </vx-card>
    </vs-col>
    <!-- 心電心音 -->
    <vs-col class="my-2" v-if="opinionType==12">
      <div class="vx-col w-full sm:w-1/1 lg:w-1/2 mb-base mx-auto" v-for="(item, index) in heartRateList" :key="index">
        <vx-card  :title="$t('Pulse')+item.HeartRate" class="p-2">
            <div class="flex justify-between flex-wrap">
              <h5 class="mb-2 flex">{{$t('TimeLabel')}} {{item.receiveTime}}</h5>
            </div>
            <vs-divider />
            <div class="text-center">
                <vx-tooltip :text="$t('dicomdescription2')">
                  <div>
                    <h4 class="mb-2 flex">{{$t('dicomdescription3')}} | {{item.PercentEMAT}}</h4>
                    <p><span class="percent" :style="{width:S1(item.PercentEMAT)}"></span><span class="percent-left" :style="{width:S1(100-item.PercentEMAT)}"></span></p>
                  </div>
                </vx-tooltip>
              <vx-tooltip :text="$t('dicomdescription4')">
                <h4 class="mb-2 flex">
                    {{$t('dicomdescription5')}} | {{ item.SDI }}</h4>
                    <span class="box1"></span><span class="box4"></span>
                    <p><svg height="10" width="100%">
                      <path :d="S3(item.SDI)"/>
                    </svg></p>
              </vx-tooltip>
              <vx-tooltip :text="$t('dicomdescription6')">
                <h4 class="mb-2 flex">
                    {{$t('dicomdescription7')}} | {{item.S3Strength}}</h4>
                    <span class="box1"></span><span class="box4"></span>
                    <p><svg height="10" width="100%">
                      <path :d="S3(item.S3Strength)"/>
                    </svg></p>
              </vx-tooltip>
              <vx-tooltip :text="$t('dicomdescription8')">
                <h4 class="mb-2 flex">
                    {{$t('dicomdescription9')}} | {{item.S4Strength}}</h4>
                    <span class="box1"></span><span class="box4"></span>
                    <p><svg height="10" width="100%">
                      <path :d="S3(item.S4Strength)"/>
                    </svg></p>
              </vx-tooltip>
            </div>
        </vx-card>
      </div>
    </vs-col>
    <!-- img -->
    <vs-col :gutter="0" type="flex" justify="center" v-if="opinionType==0||opinionType==3||opinionType==4 || (opinionType>=6 && opinionType<=10)">
        <el-col v-viewer :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="my-2">
            <img class="ml-2" :src="imageurl"  width="350px" height="250px">
        </el-col>
    </vs-col>
    <!-- video -->
    <vs-col :gutter="0" type="flex" justify="center" v-if="opinionType==11">
        <el-col v-viewer :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="my-2">
          <video width="100%" height="350px" controls="controls" autoplay="autoplay" :src="videofile"></video>
        </el-col>
    </vs-col>
    <!-- DICOM -->
    <vs-col class="my-2" v-if="opinionType==2">
      <vx-card title="DICOM" class="p-2">
        <iframe width="100%"
        height="500vh"
        :src="dicomurl"
        frameborder="0" allowfullscreen></iframe>
      </vx-card>
    </vs-col>
    <vs-popup fullscreen class="holamundo" title="DICOM" :active.sync="popupActivo" style="margin:0px;padding:0px;overflow:hidden">
      <iframe :src="selecturl" frameborder="0" style="overflow:hidden;height:88vh;width:100%" width="100%"></iframe>
    </vs-popup>
  </div>
</template>

<script>
import axios from 'axios'
import {showuploaddoctor, fetchEmrImage, getamorecgdata } from '../api/user'
import LineChart from '@/api/LineChart.js'
export default {
  components: {
    LineChart
  },
  data() {
    return {
      opinion: '',
      imageurl: null,
      videofile:null,
      pdfurl:null,
      dicomurl:null,
      loading: false,
      selectid: -1,
      opinionfilterid:-1,
      caseid: -1,
      tokenId: localStorage.getItem('di_tokenId'),
      heartRateList: null,
      diacomdataList:null,
      popupActivo:false,
      selecturl:'',
      check_ecg_chart:false,
      ECGData:  {
        labels: [],
        datasets: [{
          data: [],
          fill: false,
          borderColor: 'rgb(255, 99, 132)',
          borderWidth: 2,
          pointStyle:'line'
        }],
      },
      date:'',
      selectpid:''
    }
  },
  async created(){
    var _self = this
    await _self.$store.dispatch('fetchProfile')
  },
  computed: {
    windowWidth() {
      var windowwidth = this.$store.state.windowWidth
      return windowwidth
    },
    opinionState() {
      return this.$store.state.opinionState
    },
    opinionStatus() {
      return this.$store.getters.opinionStatus
    },
    opinionId() {
      return this.$store.state.opinionId
    },
    opinionType(){
      return this.$store.getters.opinionType
    },
    opinionAllData() {
      console.log('opinionAllData1',this.$store.getters.opinionAllData)
      return this.$store.getters.opinionAllData
    },
    myProfile: {
      get() {
        return this.$store.getters.profile
      },
      set() {
      }
    },
    // syncSwitch: {
    //   get() {
    //     return this.$store.getters.syncSwitch
    //   },
    //   set(val) {
    //     if(this.roomid!=0){
    //       console.log('sync dicom set ',val)
    //       if(val==true){
    //         this.$socket.client.emit('post',{tag: 'emr', type: 'SET_SYNC_STATUS', trigger: val})
    //       }
    //       this.$store.commit('SET_SYNC_SWITCH', val)
    //       this.$store.commit('SET_SYNC_STATUS', val)
    //     }
    //   }
    // },
  },
  async mounted() {
    this.selectid = this.$route.params.opinionfilterid;
    this.caseid = this.$route.params.caseid;
    if(this.caseid){
      console.log('select id ', this.selectid)
      console.log('select caseid ', this.caseid)
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.tokenId;
      await axios.get(process.env.VUE_APP_API_URL_HIS+'/api/opiniondata/'+this.caseid).then((res) => {
        console.log('op data ', res)
        this.$store.commit('SET_OPINION_ALL_DATA',res.data.msg)
        this.$store.commit('SET_OPINION_TYPE',res.data.msg.type)
        this.$store.commit('SET_OPINION_ID', res.data.msg.id)
        this.$store.commit('SET_OPINION_STATUS',res.data.msg.status)
        if(res.data.status=='OK'){
          //被邀請會診
          if(this.selectid==1)
          {
            this.opinion = res.data.msg.opinion
          }
          //邀請會診
          if(this.selectid==2)
          {
            this.opinion = res.data.msg.message
          }
        }
      }).catch((error) => { 
        console.log(error)
      })
    }
    //判斷類型=12觸發 心電心音
    if(this.opinionAllData.type==12){
      axios.get(process.env.VUE_APP_API_URL+'/admin/amor_sti/'+this.opinionAllData.pid
      +'/'+String(this.opinionAllData.typeid)).then(res =>{
        this.heartRateList = res.data
        console.log(res.data)
      })
    }
    //判斷類型=2觸發 dicom
    if(this.opinionAllData.type==2){
      let data = {
        "spid":this.opinionAllData.pid,
        "type":this.opinionAllData.type,
        "page":0
      }
      await fetchEmrImage(data).then((resp)=>{
        console.log("resp.data.items",resp.data.items)
        //過濾只有該類型資訊
        this.diacomdataList = resp.data.items.filter(items=>items.id==this.opinionAllData.typeid)
        // console.log('diacomdataList',this.diacomdataList[0])
      })
    }
    await this.getOpinion()
    await this.showuploaddoctorcare()
  },
  methods: {
    //讀取所有類型
    getTypeName(value) {
      const statusMap = {
        0:'['+this.$t('medicalReport0')+']',
        1: '['+this.$t('medicalReport2')+']',
        2: '['+this.$t('medicalReport3')+']',
        3: '['+this.$t('medicalReport4')+']',
        4: '['+this.$t('medicalReport5')+']',
        5: '['+this.$t('medicalReport6')+']',
        6:'['+this.$t('medicalReport7')+'-'+this.$t('medicalReport8')+']',
        7:'['+this.$t('medicalReport7')+'-'+this.$t('medicalReport9')+']',
        8:'['+this.$t('medicalReport7')+'-'+this.$t('medicalReport10')+']',
        9:'['+this.$t('medicalReport7')+'-'+this.$t('medicalReport11')+']',
        10:'['+this.$t('medicalReport7')+'-'+this.$t('medicalReport12')+']',
        11:'['+this.$t('medicalReport14')+']',
        12:'['+this.$t('medicalReport13')+']'
      }
      return statusMap[value]
    },
    //處理狀態
    opinionStatus_methods(status){
      const statusMap = {
        0:this.$t('Processing2'),
        1: this.$t('Finish'),
      }
      return statusMap[status]
    },
    //心電心音s1
    S1(a){
      return a+'%'
    },
    //心電心音s2
    S2(a){
      return a
    },
    //心電心音s3
    S3(point){
      return 'M '+point*16.9+' 0 L'+(point*17-5)+' 10 L '+(point*17+5)+' 10 Z'
    },
    //顯示樣板圖片或是pdf或是畫圖
    showuploaddoctorcare(){
      showuploaddoctor(this.opinionAllData.typeid).then((resp)=>{
        var uploadGeneralImage = process.env.VUE_APP_UPLOAD+"/generalimage/",
        uploadReportImage = process.env.VUE_APP_UPLOAD+'/report/',
        uploadFunduscopyImage = process.env.VUE_APP_UPLOAD+'/funduscopy/',
        uploadUltraSoundImage = process.env.VUE_APP_UPLOAD+'/ultrasound/',
        uploadelEctrocardiogramImage = process.env.VUE_APP_UPLOAD+'/electrocardiogram/',
        uploadearImage = process.env.VUE_APP_UPLOAD+'/ear/',
        uploadnasalImage = process.env.VUE_APP_UPLOAD+'/nasal/',
        uploadthroatImage = process.env.VUE_APP_UPLOAD+'/throat/',
        uploadoralImage = process.env.VUE_APP_UPLOAD+'/oral/',
        uploadskinImage = process.env.VUE_APP_UPLOAD+'/skin/'
        var upload_photo_path = [
          uploadGeneralImage,uploadReportImage,null,uploadFunduscopyImage,uploadUltraSoundImage,uploadelEctrocardiogramImage,
          uploadearImage,uploadnasalImage,uploadthroatImage,uploadoralImage,uploadskinImage
        ];
        if(resp.data.status=="OK" && resp.data.data.length!=0){
          console.log("resp.data.data",resp.data.data)
          if(resp.data.data[0]['type']==1||(resp.data.data[0]['type']==5&&this.opinionAllData.modality!='cha')){
            this.pdfurl = "https://patient.curdoctor.com.tw/PDFviewer/web/viewer.html?file="+upload_photo_path[resp.data.data[0]['type']]+resp.data.data[0]['imagefile']
          }else if(resp.data.data[0]['type'] == 5&&this.opinionAllData.modality=='cha'){
            console.log('typeid',this.opinionAllData.typeid)
            getamorecgdata(this.opinionAllData.typeid).then((res)=>{
              console.log("getamorecgdata.res",res)
              this.date = res.data.data.createdata   
              this.selectpid = res.data.data.pid
              console.log("getamorecgdata.this.date",res.data.data.pid)
              this.check_ecg_chart=true
            })
          }else if(resp.data.data[0]['type']==11){
            this.videofile = process.env.VUE_APP_UPLOAD+'/video/'+resp.data.data[0]['imagefile']
          }else if(resp.data.data[0]['type']==2){
            this.dicomurl = "https://ds-dev.curdoctor.com.tw/stone-webviewer/index.html?study="+this.diacomdataList[0].studies+'&series='+this.diacomdataList[0].series
          }else{
            console.log("imageurl",upload_photo_path[resp.data.data[0]['type']]+resp.data.data[0]['imagefile'])
            this.imageurl = upload_photo_path[resp.data.data[0]['type']]+resp.data.data[0]['imagefile']
          }
        }
      })
    },
    saveOpinion() {
      let _self = this
      console.log("_self.selectid",_self.selectid)
      //被邀請
      if(_self.selectid==1)
      {
        var data = {
          type: this.$store.getters.opinionType,
          id: _self.opinionId,
          opinion: _self.opinion,
          status: 0
        }
      }
      //邀請
      if(_self.selectid==2)
      {
        var data = {
          type: this.$store.getters.opinionType,
          id: _self.opinionId,
          message: _self.opinion,
          status: 0
        }
      }

      axios.defaults.headers.common['Authorization'] = 'Bearer ' + _self.tokenId;
      axios.put(process.env.VUE_APP_API_URL_HIS+'/api/opinion', data).then((res) => {
        if(res.data.status=='OK'){
          _self.$vs.notify({
                color: 'grey',
                title: _self.$t('UpdateSuccessful'),
                text: '已回覆訊息給會診醫師!',
                position:'top-center'
            })
          _self.$router.push('/opinion')
        }else{
          _self.$vs.notify({
                  color: 'danger',
                  title: _self.$t('UpdateFailed'),
                  text: _self.$t('CheckField'),
                  position:'top-center'
              })
          _self.$store.commit('SET_OPINION_STATE', false)
        }
      }).catch((error) => { 
        console.log(error)
      }).then(() => {
        
        _self.popupEyeOpinion = false
      })
    },
    getOpinion() {
      let _self = this
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + _self.tokenId;
      axios.get(process.env.VUE_APP_API_URL_HIS+'/api/opiniondata/'+_self.selectid, ).then((res) => {
        if(res.data.status=='OK'){}

      }).catch((error) => { 
        console.log(error)
      }).then(() => {
        
      })
    },
    backFunction() {
        this.$router.push('/opinion')
    },
    // //查看dicom
    // viewDicom(index, uuid, item) {
    //   var _self = this
    //   _self.popupActivo=true
    //   _self.selecturl = 'https://doctorcare.ebmtech.com/html5/showImage.html?User=1&Password=1&PatientID='+item.uuid+'&Modality='+item.Modality+'&v='+(+new Date())
    // },
    // closedicom() {
    //   if(this.syncSwitch==true){
    //     this.$socket.client.emit('post',{tag: 'emr', type: 'event', trigger: 'closedicom'})
    //   }
    // },
  },
  destroyed() {
    this.$store.commit('SET_OPINION_STATE', false)
    this.$store.commit('SET_OPINION_ID', null)
    this.$store.commit('SET_OPINION_TYPE',null)
    this.$store.commit('SET_OPINION_STATUS',null)
    this.$store.commit('SET_OPINION_ALL_DATA',null)
  },
}
</script>

<style>
  .viewer-button {
    top: 130px !important;
  }
  .image {
      cursor: pointer;
      margin: 5px;
      display: inline-block;
  }
  .left {
      float: left;
      width: 60px;
  }

  .right {
      float: right;
      width: 60px;
  }

  .bottom {
      clear: both;
      text-align: center;
  }

  .item {
      margin: 4px;
      height: 150px;
  }

  .left .el-tooltip__popper,
  .right .el-tooltip__popper {
      padding: 8px 10px;
  }
  .box1{
		width:50%;
		background-color:rgb(28, 186, 118);
		display:inline-block;
		height:5px;
	}
	.box2{
		width:25%;
		background-color:rgb(252, 186, 61);
		display:inline-block;
		height:5px;
	}
	.box3{
		width:25%;
		background-color:rgb(237, 85, 101);
		display:inline-block;
		height:5px;
	}
	.box4{
		width:50%;
		background-color:rgb(237, 85, 101);
		display:inline-block;
		height:5px;
	}
  .percent{
		background-color:rgb(28, 186, 118);
		display:inline-block;
		height:11px;
	}
	.percent-left{
		background-color:rgb(220,220,220);
		display:inline-block;
		height:10px;
	}
</style>